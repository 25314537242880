/**
 * Main card-container
*/
.card-container {
  @extend .flex-center;
  @extend .flex-col;
  @extend .p-4;
  @extend .radius-16;
  display: flex;
  background-color: var(--grey-900);
}

.highlight-card {
  @extend .card-container;
  @extend .flex-start;
  flex-basis: 225px;
  flex-shrink: 0.01;
  height: auto;

  &.stretch {
    flex-grow: 1;
  }

  .title {
    @extend .flex;
    @extend .flex-col;
    @extend .gap-2;
    align-self: flex-start;
    width: 100%;

    .highlight-extra {
      @extend .flex;
      @extend .flex-between;
      height: 32px;
    }

    .trend-base {
      @extend .flex;
      @extend .flex-col;
      @extend .flex-end;
    }

    .trend-label {
      @extend .flex-end;
      @extend .radius-6;
      @extend .py-1;
      @extend .px-2;
      align-items: center;
      gap: 8px;
      background-color: var(--grey-700);
    }

    .max {
      font-weight: 600;
      font-size: 3rem;
      white-space: nowrap;
    }

    .min {
      font-weight: 900;
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .description {
    align-self: flex-start;
    white-space: nowrap;
  }

  &.profit .title .max {
      color: var(--green);
  }

  &.loss .title .max {
    color: var(--red);
  }
}

a.highlight-card {
  color: inherit;
}
