.newton-page-header {
  @extend .mb-8;
  @extend .flex;
  @extend .gap-4;
  @extend .flex-row;
  @extend .align-center;
}

.newton-landing-card {
  @extend .p-2;
  @extend .radius-8;
  @extend .bg-grey-900;
  @extend .color-white;
  @extend .flex;
  @extend .flex-col;
  @extend .flex-between;
  flex-basis: 250px;
  flex-shrink: 0;
  min-height: 100px;
  max-width: 100%;
  word-break: break-word;
}

.newton-analysis-description {
  .MuiTiptap-FieldContainer-notchedOutline {
    border: none;
  }
}

.newton-video-modal {
    max-height: 65vh;
    overflow: auto;
    width: '100%'
}